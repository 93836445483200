import React, {ReactNode} from "react";

import Label from "./Label";
import Paragraph from "../../primitives/Paragraph";
import Stack from "../../primitives/Stack";

type FieldWrapperProps = {
    children: ReactNode;
    description?: string;
    error?: string;
    id: string;
    label?: string;
    required?: boolean;
};

export default function FieldWrapper({children, description, error, id, label, required}: FieldWrapperProps) {
    return (
        <Stack className="ng-field-wrapper" gap="extra-small">
            {label && <Label id={id} required={required} text={label} />}
            {children}
            <Stack gap="none">
                {description && (
                    <Paragraph size="small" textColor="grey">
                        {description}
                    </Paragraph>
                )}
                {error && (
                    <Paragraph size="small" textColor="red">
                        {error}
                    </Paragraph>
                )}
            </Stack>
        </Stack>
    );
}
