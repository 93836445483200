import React from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

type InputAdornmentProps = {
    adornment: string | IconDefinition;
    onClick?: () => void;
};

export function InputAdornment({adornment, onClick}: InputAdornmentProps) {
    if (typeof adornment === "string") {
        return (
            <span className={classNames("ng-input-adornment", {"ng-clickable": onClick})} onClick={onClick}>
                {adornment}
            </span>
        );
    }

    return (
        <FontAwesomeIcon
            icon={adornment}
            className={classNames("ng-input-adornment", {"ng-clickable": onClick})}
            onClick={onClick}
            fixedWidth
        />
    );
}
