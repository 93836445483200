import React, {ReactNode} from "react";

import Actions from "../actions/Actions";
import Button from "../actions/Button";
import Stack from "../primitives/Stack";

type FormProps = {
    children: ReactNode;
    className?: string;
    isDirty?: boolean;
    isSubmitting?: boolean;
    onSubmit: () => void;
    hideAction?: boolean;
    submitButtonFullWidth?: boolean;
    submitButtonJustify?: "center" | "left";
    submitButtonText: string;
};

export default function Form({
    children,
    className,
    isDirty,
    isSubmitting = false,
    onSubmit,
    hideAction = false,
    submitButtonFullWidth,
    submitButtonJustify = "center",
    submitButtonText,
}: FormProps) {
    return (
        <form
            className={className}
            onSubmit={(event) => {
                event?.preventDefault();
                onSubmit();
            }}
        >
            <Stack>
                {children}
                {!hideAction && (
                    <Actions justify={submitButtonJustify}>
                        <Button
                            fullWidth={submitButtonFullWidth}
                            onClick={() => undefined}
                            loading={isSubmitting}
                            disabled={isDirty === false}
                            text={submitButtonText}
                            type="submit"
                        />
                    </Actions>
                )}
            </Stack>
        </form>
    );
}
